import React from 'react';
import dynamic from 'next/dynamic';

import { useAppStateContext } from '../../hooks/context';
import { useTranslation } from '../../hooks/translation';
import AppContainer from '../AppContainer/AppContainer';
import AppImage from '../AppImage';
import AppLink from '../AppLink';

import styles from './AppOurServices.module.scss';

const AppText = dynamic(() => import('../AppText/AppText'));

export default function AppOurServices({ services }) {
  const { parameters } = useAppStateContext().context;
  const { translate } = useTranslation();

  const serviceTitle = parameters?.data?.find((x) => x.keyword === 'home_services_title')?.value;
  const serviceText = parameters?.data?.find((x) => x.keyword === 'home_services_text')?.value;

  if (!services) return null;

  return (
    <section className={styles.AppOurServices}>
      <AppContainer>
        <div className={styles.AppOurServices_Inner}>
          <div className={styles.AppOurServices_Left}>
            <div className={styles.AppOurServices_Left_Title}>
              <h3 className={styles.AppOurServices_Title}>{serviceTitle}</h3>
            </div>
            <div className={styles.AppOurServices_Left_Desc}>
              <AppText>{serviceText}</AppText>
            </div>
          </div>
          <div className={styles.AppOurServices_Right}>
            {services.data?.map((item, i) => (
              <div className={styles.AppOurService} key={i}>
                <div className={styles.AppOurService_Inner}>
                  <figure>
                    <AppImage src={item?.image.url} alt={item.title}/>
                  </figure>
                  <h2>{item.title}</h2>
                  <div className={styles.AppOurService_Text}>{item.desc}</div>
                  <div className={styles.AppOurService_Link}>
                    <AppLink
                      href={`${services?.parent_slug}/${item.slug}`}
                      ariaLabel={translate('read_more')}
                      title={translate('read_more')}
                      className={styles.AppOurService_Arrow}>
                      <strong>{translate('read_more')}</strong>
                      <Arrow_Right />
                    </AppLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </AppContainer>
    </section>
  );
}

function Arrow_Right() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 8H15.5M15.5 8L8.5 1M15.5 8L8.5 15"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
